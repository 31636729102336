import React from 'react'
import {HashLink as Link} from "react-router-hash-link";
import linkedin from '../Images/linkedinLogo.svg'
import github from '../Images/githubLogo.svg'
import expand from '../Images/menuDark.svg'
import './Navbar.css'

export default function Navbar() {
    const [showNav, setShowNav] = React.useState(false);
    function handleShow(e) {
        setShowNav(prev => !prev);
    }
    
    return (
    <div className='navbar'>
        <div className='navbar-main'>
            <div className='navbar-links'>
                <Link to='#about' className='links'><h5>ABOUT ME</h5></Link>
                <Link to='#project' className='links'><h5>PROJECTS</h5></Link>
                <Link to='#contacts' className='links'><h5>CONTACT</h5></Link>
            </div>

            <div className='navbar-mobile'>
                <button onClick={handleShow} className='expand-button'>
                    <img src={expand} alt='expand'/>
                </button> 
            </div>

            <div className='navbar-logos'>
                <a href='https://www.linkedin.com/in/ligiatmonteiro/' target="_blank" rel="noreferrer noopener">
                    <img src={linkedin} alt="linkedin" className='logo-linkedin'/>
                </a>
                <a href='https://github.com/IamGrootie' target="_blank" rel="noreferrer noopener">
                    <img src={github} alt="github" className='logo-github'/>
                </a>
            </div>
        </div>

        <div className='navbar-mobile'>
            <div className={showNav ? 'links-show' : 'links-hide'}>
                <Link to='#about' className='links'><h5>ABOUT ME</h5></Link>
                <Link to='#project' className='links'><h5>PROJECTS</h5></Link>
                <Link to='#contacts' className='links'><h5>CONTACT</h5></Link>
            </div>
        </div>
    </div>
  )
}
