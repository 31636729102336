import React, { Component } from 'react'
import './About.css'
import react from '../Images/react.svg'
import angular from '../Images/angular.svg'
import html from '../Images/html.svg'
import css from '../Images/css.svg'
import js from '../Images/js.svg'
import node from '../Images/nodejs.svg'
import cv from '../CV_LigiaMonteiro.pdf'

export default class About extends Component {
  render() {
    return (
        <div className='about' id='about'>
            <h2>About me</h2>
            <div className='about-section'>
            <section className='about-text'>
                <h3>My name is Lígia Monteiro. I'm a <span className='mint'>Front-end developer</span> based in Porto, Portugal</h3>
                <p> My name is Lígia Monteiro. I'm a Front-end developer based in Porto, Portugal.
                    I'm a technology geek and always seeking to learn something new. This trait makes me dive in and keep myself motivated to be up to date.
                    I'm a CSS enthusiast and could spend hours working on it. Adding effects and transformations to achieve the best UI, I can develop intuitive, dynamic user experiences.
                    I feel like I found a career that allows me to have so much fun doing it that it barely seems like a job. 
                    <br/><br/>During my free time, you may find me at the gym or cosying under a blanket, watching a movie or reading, always with music in my background. 
                    <br/><br/><span className='bold'>Fun fact about me:</span> I am what people call a bookworm and read over 100 books a year.
                </p>
                <a href={cv} ><h4 className='download-cv'>Download CV</h4></a>
            </section>
            <section className='cube-section'>
                <div className='full-cube'>
                <div className='cube face-1'>
                    <img src={react} alt='react'/>
                </div>
                <div className='cube face-2'>
                    <img src={css} alt='css'/>
                </div>
                <div className='cube face-3'>
                    <img src={html} alt='html'/>
                </div>
                <div className='cube face-4'>
                    <img src={angular} alt='angular'/>
                </div>
                <div className='cube face-5'>
                    <img src={js} alt='js'/>
                </div>
                <div className='cube face-6'>
                    <img src={node} alt='node'/>
                </div>
                </div>
            </section>
            </div>
        </div>
    )
  }
}
