import React, { Component } from 'react'
import './Intro.css'
import frame from '../Images/vscodeFrame.svg';

export default class Intro extends Component {
  render() {
    return (
        <div className='intro' >

            <div className='intro-title'>
              <h1 className='title-mint'><span className='title-inverse'>!</span>Hi!</h1>
              <h1>I'm Lígia and I'm</h1>
              <h1 className='title-mint-border'>a front-end developer.</h1>
            </div>

            <div className='intro-img'>
              <img src={frame} alt='frame' />
            </div>
            
        </div>
    )
  }
}
