import React from 'react'
import { useNavigate } from "react-router-dom";
import './Portfolio.css'


export default function Portfolio() {
  const navigate = useNavigate();


  return (
    <div className='portfolio'>
      <div className='btn-section'>
        <button onClick={() => navigate(-1)} className='btn-back'>
          <span class="material-symbols-outlined">
            chevron_left
          </span>
        </button>
      </div>
      <div className='portfolio-container'>
          <h3>This page is undergoing renovations</h3>
      </div>
      <p>Reach me through</p>
      <a href='mailto:ligiatmonteiro@gmail.com' className='email-link'>ligiatmonteiro@gmail.com</a>
    </div>
  )
}
