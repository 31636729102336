import React from 'react'
import top from '../Images/chevron-up.svg'

export default function ButtonToTop() {
    const [topButton, setTopButton] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener('scroll', () => {
        if(window.scrollY > 200 ) {
            setTopButton(true);
        } else {
            setTopButton(false);
        }
        })
    }, []); 

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    } 
  
    return (
    <div>
        {topButton && 
        <button 
            className='top-button'
            onClick={scrollUp}
        ><img src={top} alt='chevron up'/>
        </button>
        }
    </div>
    )
}
