import React from 'react'
import './Footer.css'
import linkedin from '../Images/linkedinLogo.svg'
import github from '../Images/githubLogo.svg'

export default function Footer() {
  return (
    <div className='footer-section' id='contacts'>
        <div className='logos-section'>
            <a href='https://www.linkedin.com/in/ligiatmonteiro/' target="_blank" rel="noreferrer noopener">
              <img src={linkedin} alt="linkedin" className='logo-linkedin'/>
            </a>
            <a href='https://github.com/IamGrootie' target="_blank" rel="noreferrer noopener">
              <img src={github} alt="github" className='logo-github'/>
            </a>
        </div>
        <h2>Do you have a project in mind?</h2>
        <a href='mailto:ligiatmonteiro@gmail.com' className='email-link' target="_blank" rel="noreferrer noopener">
          <h4 className='link'>ligiatmonteiro@gmail.com</h4>
        </a>
        <div className='end-section'>
            <p>2022 - All the rights reserved</p>
        </div>
    </div>
  )
}
