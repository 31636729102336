import {Route, Routes} from "react-router-dom";
import './App.css';
import Portfolio from "./Components/Portfolio";
import Navbar from './Components/Navbar';
import Intro from './Components/Intro';
import About from './Components/About';
import Projects from './Components/Projects';
import Footer from './Components/Footer';
import ButtonToTop from "./Components/ButtonToTop";
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  AOS.init({
    offset: 200,
    duration: 1500,
  });

  return (
    <div className="App" >
      
      <Routes>
    
        <Route 
          path="/" 
          element={
            < >
              <div id="stars"></div>
              <div id="stars2"></div>
              <div id="stars3"></div>
              <Navbar />
              <Intro />
              <div data-aos="fade-up">
                <About />
              </div>
              <div data-aos="fade-up">
                <Projects />
              </div>
              <Footer />
              <ButtonToTop />
            </ >
          } 
        />

        <Route
          path="portfolio"
          element={
            <>
              <div id="star"></div>
              <div id="star2"></div>
              <div id="star3"></div>
              <Portfolio />
            </>
          }
        />

        <Route path="*" element={<div>Page not found</div>} />
      </Routes>
    </div>
  );
}

export default App;
