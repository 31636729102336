import React from 'react'
import { NavLink } from "react-router-dom";
import './Projects.css'

export default function Projects() {
  return (
    <div className='project-section' id='project'>
        <h2 className='project-section-title'>My Projects</h2>
        
        <div className='projects'>
            <div className='project-preview expenses'>
                <div className='project-content'>
                    <h3 className='card-title'>Personal Expenses Tracker App</h3>
                    <div className='tags'>
                        <h6 style={{color: 'hsl(192, 95%, 68%, 0.8)'}}>React</h6>
                        <h6 style={{color: 'hsl(38, 100%, 53%, 0.8)'}}>Firebase</h6>
                    </div>
                    <p>This project is a expense tracker application fully developed with React. 
                        The account and database information was built with Firebase from Google, 
                        which provide users with the ability to sign in with Google alongside classic e-mail/password authentication.</p>
                    <div className='project-btns'>
                        <a href='https://expensestrackerlm.netlify.app/' className='button' target="_blank" rel="noreferrer noopener"><h4>View</h4></a>
                        <a href='https://github.com/IamGrootie/React-Expenses-Tracker' className='button' target="_blank" rel="noreferrer noopener"><h4>Code</h4></a>
                    </div>
                </div>
            </div>
            
            <div className='project-preview yurban'>
                <div className='project-content'>
                    <h3 className='card-title'>Yurban Gym Website</h3>
                    <div className='tags'>
                        <h6 style={{color: 'hsl(52, 84%, 63%, 0.8)'}}>JavaScript</h6>
                        <h6 style={{color: 'hsl(12, 77%, 52%, 0.8)'}}>HTML</h6>
                        <h6 style={{color: 'hsl(205, 70%, 55%, 0.8)'}}>CSS</h6>
                    </div>
                    <p>Website of an example to a gym. This project was developed through dynamic HTML.</p>
                    <div className='project-btns'>
                        <a href='https://yurbanstudio.netlify.app/' className='button' target="_blank" rel="noreferrer noopener"><h4>View</h4></a>
                        <a href='https://github.com/IamGrootie/GymWebsite' className='button' target="_blank" rel="noreferrer noopener"><h4>Code</h4></a>
                    </div>
                </div>
            </div>
        </div>

        <NavLink to="portfolio" className="others">
            <h4 style={{textAlign: 'center', marginTop: '3rem'}}>Other Projects</h4>
        </NavLink>
        
    </div>
  )
}
